/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedBillingImport } from './routes/_authenticated/billing'
import { Route as AuthenticatedLayoutImport } from './routes/_authenticated/_layout'
import { Route as AuthenticatedLayoutIndexImport } from './routes/_authenticated/_layout/index'
import { Route as AuthenticatedInviteInviteCodeImport } from './routes/_authenticated/invite.$inviteCode'
import { Route as AuthenticatedDevicesLinkImport } from './routes/_authenticated/devices.link'
import { Route as AuthenticatedLayoutMyProfileImport } from './routes/_authenticated/_layout/my-profile'
import { Route as AuthenticatedLayoutMyProfileIndexImport } from './routes/_authenticated/_layout/my-profile/index'
import { Route as AuthenticatedDevicesLinkTokenImport } from './routes/_authenticated/devices.link_.$token'
import { Route as AuthenticatedLayoutTeamsGroupIdImport } from './routes/_authenticated/_layout/teams/$groupId'
import { Route as AuthenticatedLayoutProjectsProjectNameIdImport } from './routes/_authenticated/_layout/projects/$projectNameId'
import { Route as AuthenticatedLayoutMyProfileFeaturesImport } from './routes/_authenticated/_layout/my-profile/features'
import { Route as AuthenticatedLayoutGamesSplatImport } from './routes/_authenticated/_layout/games/$'
import { Route as AuthenticatedLayoutTeamsGroupIdIndexImport } from './routes/_authenticated/_layout/teams/$groupId/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdIndexImport } from './routes/_authenticated/_layout/projects/$projectNameId/index'
import { Route as AuthenticatedLayoutTeamsGroupIdSettingsImport } from './routes/_authenticated/_layout/teams/$groupId/settings'
import { Route as AuthenticatedLayoutTeamsGroupIdMembersImport } from './routes/_authenticated/_layout/teams/$groupId/members'
import { Route as AuthenticatedLayoutTeamsGroupIdBillingImport } from './routes/_authenticated/_layout/teams/$groupId/billing'
import { Route as AuthenticatedLayoutProjectsProjectNameIdTokensImport } from './routes/_authenticated/_layout/projects/$projectNameId/tokens'
import { Route as AuthenticatedLayoutProjectsProjectNameIdSettingsImport } from './routes/_authenticated/_layout/projects/$projectNameId/settings'
import { Route as AuthenticatedLayoutProjectsProjectNameIdBillingImport } from './routes/_authenticated/_layout/projects/$projectNameId/billing'
import { Route as AuthenticatedLayoutTeamsGroupIdSettingsIndexImport } from './routes/_authenticated/_layout/teams/$groupId/settings/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdSettingsIndexImport } from './routes/_authenticated/_layout/projects/$projectNameId/settings/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatImport } from './routes/_authenticated/_layout/projects/$projectNameId/namespaces/$'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/index'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables'
import { Route as AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsImport } from './routes/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedBillingRoute = AuthenticatedBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedLayoutRoute = AuthenticatedLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedLayoutIndexRoute = AuthenticatedLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedLayoutRoute,
} as any)

const AuthenticatedInviteInviteCodeRoute =
  AuthenticatedInviteInviteCodeImport.update({
    id: '/invite/$inviteCode',
    path: '/invite/$inviteCode',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedDevicesLinkRoute = AuthenticatedDevicesLinkImport.update({
  id: '/devices/link',
  path: '/devices/link',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedLayoutMyProfileRoute =
  AuthenticatedLayoutMyProfileImport.update({
    id: '/my-profile',
    path: '/my-profile',
    getParentRoute: () => AuthenticatedLayoutRoute,
  } as any)

const AuthenticatedLayoutMyProfileIndexRoute =
  AuthenticatedLayoutMyProfileIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutMyProfileRoute,
  } as any)

const AuthenticatedDevicesLinkTokenRoute =
  AuthenticatedDevicesLinkTokenImport.update({
    id: '/devices/link_/$token',
    path: '/devices/link/$token',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdRoute =
  AuthenticatedLayoutTeamsGroupIdImport.update({
    id: '/teams/$groupId',
    path: '/teams/$groupId',
    getParentRoute: () => AuthenticatedLayoutRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdRoute =
  AuthenticatedLayoutProjectsProjectNameIdImport.update({
    id: '/projects/$projectNameId',
    path: '/projects/$projectNameId',
    getParentRoute: () => AuthenticatedLayoutRoute,
  } as any)

const AuthenticatedLayoutMyProfileFeaturesRoute =
  AuthenticatedLayoutMyProfileFeaturesImport.update({
    id: '/features',
    path: '/features',
    getParentRoute: () => AuthenticatedLayoutMyProfileRoute,
  } as any)

const AuthenticatedLayoutGamesSplatRoute =
  AuthenticatedLayoutGamesSplatImport.update({
    id: '/games/$',
    path: '/games/$',
    getParentRoute: () => AuthenticatedLayoutRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdIndexRoute =
  AuthenticatedLayoutTeamsGroupIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutTeamsGroupIdRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdIndexRoute =
  AuthenticatedLayoutProjectsProjectNameIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdSettingsRoute =
  AuthenticatedLayoutTeamsGroupIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedLayoutTeamsGroupIdRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdMembersRoute =
  AuthenticatedLayoutTeamsGroupIdMembersImport.update({
    id: '/members',
    path: '/members',
    getParentRoute: () => AuthenticatedLayoutTeamsGroupIdRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdBillingRoute =
  AuthenticatedLayoutTeamsGroupIdBillingImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => AuthenticatedLayoutTeamsGroupIdRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdTokensRoute =
  AuthenticatedLayoutProjectsProjectNameIdTokensImport.update({
    id: '/tokens',
    path: '/tokens',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdSettingsRoute =
  AuthenticatedLayoutProjectsProjectNameIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdBillingRoute =
  AuthenticatedLayoutProjectsProjectNameIdBillingImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
  } as any)

const AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute =
  AuthenticatedLayoutTeamsGroupIdSettingsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutTeamsGroupIdSettingsRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute =
  AuthenticatedLayoutProjectsProjectNameIdSettingsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdSettingsRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute =
  AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatImport.update({
    id: '/namespaces/$',
    path: '/namespaces/$',
    getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
  } as any)

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport.update(
    {
      id: '/environments/$environmentNameId',
      path: '/environments/$environmentNameId',
      getParentRoute: () => AuthenticatedLayoutProjectsProjectNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsImport.update(
    {
      id: '/versions',
      path: '/versions',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensImport.update(
    {
      id: '/tokens',
      path: '/tokens',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersImport.update(
    {
      id: '/servers',
      path: '/servers',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerImport.update(
    {
      id: '/matchmaker',
      path: '/matchmaker',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport.update(
    {
      id: '/lobbies',
      path: '/lobbies',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnImport.update(
    {
      id: '/cdn',
      path: '/cdn',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsImport.update(
    {
      id: '/builds',
      path: '/builds',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport.update(
    {
      id: '/backend',
      path: '/backend',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsImport.update(
    {
      id: '/actors',
      path: '/actors',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatImport.update(
    {
      id: '/$',
      path: '/$',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsImport.update(
    {
      id: '/settings',
      path: '/settings',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsImport.update(
    {
      id: '/logs',
      path: '/logs',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesImport.update(
    {
      id: '/variables',
      path: '/variables',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute,
    } as any,
  )

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsImport.update(
    {
      id: '/logs',
      path: '/logs',
      getParentRoute: () =>
        AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_layout': {
      id: '/_authenticated/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedLayoutImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/billing': {
      id: '/_authenticated/billing'
      path: '/billing'
      fullPath: '/billing'
      preLoaderRoute: typeof AuthenticatedBillingImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_layout/my-profile': {
      id: '/_authenticated/_layout/my-profile'
      path: '/my-profile'
      fullPath: '/my-profile'
      preLoaderRoute: typeof AuthenticatedLayoutMyProfileImport
      parentRoute: typeof AuthenticatedLayoutImport
    }
    '/_authenticated/devices/link': {
      id: '/_authenticated/devices/link'
      path: '/devices/link'
      fullPath: '/devices/link'
      preLoaderRoute: typeof AuthenticatedDevicesLinkImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/invite/$inviteCode': {
      id: '/_authenticated/invite/$inviteCode'
      path: '/invite/$inviteCode'
      fullPath: '/invite/$inviteCode'
      preLoaderRoute: typeof AuthenticatedInviteInviteCodeImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_layout/': {
      id: '/_authenticated/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedLayoutIndexImport
      parentRoute: typeof AuthenticatedLayoutImport
    }
    '/_authenticated/_layout/games/$': {
      id: '/_authenticated/_layout/games/$'
      path: '/games/$'
      fullPath: '/games/$'
      preLoaderRoute: typeof AuthenticatedLayoutGamesSplatImport
      parentRoute: typeof AuthenticatedLayoutImport
    }
    '/_authenticated/_layout/my-profile/features': {
      id: '/_authenticated/_layout/my-profile/features'
      path: '/features'
      fullPath: '/my-profile/features'
      preLoaderRoute: typeof AuthenticatedLayoutMyProfileFeaturesImport
      parentRoute: typeof AuthenticatedLayoutMyProfileImport
    }
    '/_authenticated/_layout/projects/$projectNameId': {
      id: '/_authenticated/_layout/projects/$projectNameId'
      path: '/projects/$projectNameId'
      fullPath: '/projects/$projectNameId'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
      parentRoute: typeof AuthenticatedLayoutImport
    }
    '/_authenticated/_layout/teams/$groupId': {
      id: '/_authenticated/_layout/teams/$groupId'
      path: '/teams/$groupId'
      fullPath: '/teams/$groupId'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdImport
      parentRoute: typeof AuthenticatedLayoutImport
    }
    '/_authenticated/devices/link_/$token': {
      id: '/_authenticated/devices/link_/$token'
      path: '/devices/link/$token'
      fullPath: '/devices/link/$token'
      preLoaderRoute: typeof AuthenticatedDevicesLinkTokenImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_layout/my-profile/': {
      id: '/_authenticated/_layout/my-profile/'
      path: '/'
      fullPath: '/my-profile/'
      preLoaderRoute: typeof AuthenticatedLayoutMyProfileIndexImport
      parentRoute: typeof AuthenticatedLayoutMyProfileImport
    }
    '/_authenticated/_layout/projects/$projectNameId/billing': {
      id: '/_authenticated/_layout/projects/$projectNameId/billing'
      path: '/billing'
      fullPath: '/projects/$projectNameId/billing'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdBillingImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/settings': {
      id: '/_authenticated/_layout/projects/$projectNameId/settings'
      path: '/settings'
      fullPath: '/projects/$projectNameId/settings'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdSettingsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/tokens': {
      id: '/_authenticated/_layout/projects/$projectNameId/tokens'
      path: '/tokens'
      fullPath: '/projects/$projectNameId/tokens'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdTokensImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/teams/$groupId/billing': {
      id: '/_authenticated/_layout/teams/$groupId/billing'
      path: '/billing'
      fullPath: '/teams/$groupId/billing'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdBillingImport
      parentRoute: typeof AuthenticatedLayoutTeamsGroupIdImport
    }
    '/_authenticated/_layout/teams/$groupId/members': {
      id: '/_authenticated/_layout/teams/$groupId/members'
      path: '/members'
      fullPath: '/teams/$groupId/members'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdMembersImport
      parentRoute: typeof AuthenticatedLayoutTeamsGroupIdImport
    }
    '/_authenticated/_layout/teams/$groupId/settings': {
      id: '/_authenticated/_layout/teams/$groupId/settings'
      path: '/settings'
      fullPath: '/teams/$groupId/settings'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdSettingsImport
      parentRoute: typeof AuthenticatedLayoutTeamsGroupIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/': {
      id: '/_authenticated/_layout/projects/$projectNameId/'
      path: '/'
      fullPath: '/projects/$projectNameId/'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdIndexImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/teams/$groupId/': {
      id: '/_authenticated/_layout/teams/$groupId/'
      path: '/'
      fullPath: '/teams/$groupId/'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdIndexImport
      parentRoute: typeof AuthenticatedLayoutTeamsGroupIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId'
      path: '/environments/$environmentNameId'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/namespaces/$': {
      id: '/_authenticated/_layout/projects/$projectNameId/namespaces/$'
      path: '/namespaces/$'
      fullPath: '/projects/$projectNameId/namespaces/$'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/settings/': {
      id: '/_authenticated/_layout/projects/$projectNameId/settings/'
      path: '/'
      fullPath: '/projects/$projectNameId/settings/'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdSettingsIndexImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdSettingsImport
    }
    '/_authenticated/_layout/teams/$groupId/settings/': {
      id: '/_authenticated/_layout/teams/$groupId/settings/'
      path: '/'
      fullPath: '/teams/$groupId/settings/'
      preLoaderRoute: typeof AuthenticatedLayoutTeamsGroupIdSettingsIndexImport
      parentRoute: typeof AuthenticatedLayoutTeamsGroupIdSettingsImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors'
      path: '/actors'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/actors'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend'
      path: '/backend'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/backend'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds'
      path: '/builds'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/builds'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn'
      path: '/cdn'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/cdn'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies'
      path: '/lobbies'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/lobbies'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker'
      path: '/matchmaker'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/matchmaker'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers'
      path: '/servers'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/servers'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens'
      path: '/tokens'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/tokens'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions'
      path: '/versions'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/versions'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/'
      path: '/'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs'
      path: '/logs'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/backend/logs'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables'
      path: '/variables'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/backend/variables'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
      path: '/logs'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
      path: '/settings'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$'
      path: '/$'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/servers/$'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/'
      path: '/'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/backend/'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendImport
    }
    '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/': {
      id: '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/'
      path: '/'
      fullPath: '/projects/$projectNameId/environments/$environmentNameId/lobbies/'
      preLoaderRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexImport
      parentRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedLayoutMyProfileRouteChildren {
  AuthenticatedLayoutMyProfileFeaturesRoute: typeof AuthenticatedLayoutMyProfileFeaturesRoute
  AuthenticatedLayoutMyProfileIndexRoute: typeof AuthenticatedLayoutMyProfileIndexRoute
}

const AuthenticatedLayoutMyProfileRouteChildren: AuthenticatedLayoutMyProfileRouteChildren =
  {
    AuthenticatedLayoutMyProfileFeaturesRoute:
      AuthenticatedLayoutMyProfileFeaturesRoute,
    AuthenticatedLayoutMyProfileIndexRoute:
      AuthenticatedLayoutMyProfileIndexRoute,
  }

const AuthenticatedLayoutMyProfileRouteWithChildren =
  AuthenticatedLayoutMyProfileRoute._addFileChildren(
    AuthenticatedLayoutMyProfileRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdSettingsRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute: typeof AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute
}

const AuthenticatedLayoutProjectsProjectNameIdSettingsRouteChildren: AuthenticatedLayoutProjectsProjectNameIdSettingsRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute:
      AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdSettingsRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdSettingsRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdSettingsRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute
}

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteChildren: AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute
}

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteChildren: AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute
}

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteChildren: AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteWithChildren
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteWithChildren
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute
}

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteChildren: AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteWithChildren,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteWithChildren,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteChildren,
  )

interface AuthenticatedLayoutProjectsProjectNameIdRouteChildren {
  AuthenticatedLayoutProjectsProjectNameIdBillingRoute: typeof AuthenticatedLayoutProjectsProjectNameIdBillingRoute
  AuthenticatedLayoutProjectsProjectNameIdSettingsRoute: typeof AuthenticatedLayoutProjectsProjectNameIdSettingsRouteWithChildren
  AuthenticatedLayoutProjectsProjectNameIdTokensRoute: typeof AuthenticatedLayoutProjectsProjectNameIdTokensRoute
  AuthenticatedLayoutProjectsProjectNameIdIndexRoute: typeof AuthenticatedLayoutProjectsProjectNameIdIndexRoute
  AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute: typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteWithChildren
  AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute: typeof AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute
}

const AuthenticatedLayoutProjectsProjectNameIdRouteChildren: AuthenticatedLayoutProjectsProjectNameIdRouteChildren =
  {
    AuthenticatedLayoutProjectsProjectNameIdBillingRoute:
      AuthenticatedLayoutProjectsProjectNameIdBillingRoute,
    AuthenticatedLayoutProjectsProjectNameIdSettingsRoute:
      AuthenticatedLayoutProjectsProjectNameIdSettingsRouteWithChildren,
    AuthenticatedLayoutProjectsProjectNameIdTokensRoute:
      AuthenticatedLayoutProjectsProjectNameIdTokensRoute,
    AuthenticatedLayoutProjectsProjectNameIdIndexRoute:
      AuthenticatedLayoutProjectsProjectNameIdIndexRoute,
    AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRoute:
      AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteWithChildren,
    AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute:
      AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute,
  }

const AuthenticatedLayoutProjectsProjectNameIdRouteWithChildren =
  AuthenticatedLayoutProjectsProjectNameIdRoute._addFileChildren(
    AuthenticatedLayoutProjectsProjectNameIdRouteChildren,
  )

interface AuthenticatedLayoutTeamsGroupIdSettingsRouteChildren {
  AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute: typeof AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute
}

const AuthenticatedLayoutTeamsGroupIdSettingsRouteChildren: AuthenticatedLayoutTeamsGroupIdSettingsRouteChildren =
  {
    AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute:
      AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute,
  }

const AuthenticatedLayoutTeamsGroupIdSettingsRouteWithChildren =
  AuthenticatedLayoutTeamsGroupIdSettingsRoute._addFileChildren(
    AuthenticatedLayoutTeamsGroupIdSettingsRouteChildren,
  )

interface AuthenticatedLayoutTeamsGroupIdRouteChildren {
  AuthenticatedLayoutTeamsGroupIdBillingRoute: typeof AuthenticatedLayoutTeamsGroupIdBillingRoute
  AuthenticatedLayoutTeamsGroupIdMembersRoute: typeof AuthenticatedLayoutTeamsGroupIdMembersRoute
  AuthenticatedLayoutTeamsGroupIdSettingsRoute: typeof AuthenticatedLayoutTeamsGroupIdSettingsRouteWithChildren
  AuthenticatedLayoutTeamsGroupIdIndexRoute: typeof AuthenticatedLayoutTeamsGroupIdIndexRoute
}

const AuthenticatedLayoutTeamsGroupIdRouteChildren: AuthenticatedLayoutTeamsGroupIdRouteChildren =
  {
    AuthenticatedLayoutTeamsGroupIdBillingRoute:
      AuthenticatedLayoutTeamsGroupIdBillingRoute,
    AuthenticatedLayoutTeamsGroupIdMembersRoute:
      AuthenticatedLayoutTeamsGroupIdMembersRoute,
    AuthenticatedLayoutTeamsGroupIdSettingsRoute:
      AuthenticatedLayoutTeamsGroupIdSettingsRouteWithChildren,
    AuthenticatedLayoutTeamsGroupIdIndexRoute:
      AuthenticatedLayoutTeamsGroupIdIndexRoute,
  }

const AuthenticatedLayoutTeamsGroupIdRouteWithChildren =
  AuthenticatedLayoutTeamsGroupIdRoute._addFileChildren(
    AuthenticatedLayoutTeamsGroupIdRouteChildren,
  )

interface AuthenticatedLayoutRouteChildren {
  AuthenticatedLayoutMyProfileRoute: typeof AuthenticatedLayoutMyProfileRouteWithChildren
  AuthenticatedLayoutIndexRoute: typeof AuthenticatedLayoutIndexRoute
  AuthenticatedLayoutGamesSplatRoute: typeof AuthenticatedLayoutGamesSplatRoute
  AuthenticatedLayoutProjectsProjectNameIdRoute: typeof AuthenticatedLayoutProjectsProjectNameIdRouteWithChildren
  AuthenticatedLayoutTeamsGroupIdRoute: typeof AuthenticatedLayoutTeamsGroupIdRouteWithChildren
}

const AuthenticatedLayoutRouteChildren: AuthenticatedLayoutRouteChildren = {
  AuthenticatedLayoutMyProfileRoute:
    AuthenticatedLayoutMyProfileRouteWithChildren,
  AuthenticatedLayoutIndexRoute: AuthenticatedLayoutIndexRoute,
  AuthenticatedLayoutGamesSplatRoute: AuthenticatedLayoutGamesSplatRoute,
  AuthenticatedLayoutProjectsProjectNameIdRoute:
    AuthenticatedLayoutProjectsProjectNameIdRouteWithChildren,
  AuthenticatedLayoutTeamsGroupIdRoute:
    AuthenticatedLayoutTeamsGroupIdRouteWithChildren,
}

const AuthenticatedLayoutRouteWithChildren =
  AuthenticatedLayoutRoute._addFileChildren(AuthenticatedLayoutRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedLayoutRoute: typeof AuthenticatedLayoutRouteWithChildren
  AuthenticatedBillingRoute: typeof AuthenticatedBillingRoute
  AuthenticatedDevicesLinkRoute: typeof AuthenticatedDevicesLinkRoute
  AuthenticatedInviteInviteCodeRoute: typeof AuthenticatedInviteInviteCodeRoute
  AuthenticatedDevicesLinkTokenRoute: typeof AuthenticatedDevicesLinkTokenRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedLayoutRoute: AuthenticatedLayoutRouteWithChildren,
  AuthenticatedBillingRoute: AuthenticatedBillingRoute,
  AuthenticatedDevicesLinkRoute: AuthenticatedDevicesLinkRoute,
  AuthenticatedInviteInviteCodeRoute: AuthenticatedInviteInviteCodeRoute,
  AuthenticatedDevicesLinkTokenRoute: AuthenticatedDevicesLinkTokenRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedLayoutRouteWithChildren
  '/login': typeof LoginRoute
  '/billing': typeof AuthenticatedBillingRoute
  '/my-profile': typeof AuthenticatedLayoutMyProfileRouteWithChildren
  '/devices/link': typeof AuthenticatedDevicesLinkRoute
  '/invite/$inviteCode': typeof AuthenticatedInviteInviteCodeRoute
  '/': typeof AuthenticatedLayoutIndexRoute
  '/games/$': typeof AuthenticatedLayoutGamesSplatRoute
  '/my-profile/features': typeof AuthenticatedLayoutMyProfileFeaturesRoute
  '/projects/$projectNameId': typeof AuthenticatedLayoutProjectsProjectNameIdRouteWithChildren
  '/teams/$groupId': typeof AuthenticatedLayoutTeamsGroupIdRouteWithChildren
  '/devices/link/$token': typeof AuthenticatedDevicesLinkTokenRoute
  '/my-profile/': typeof AuthenticatedLayoutMyProfileIndexRoute
  '/projects/$projectNameId/billing': typeof AuthenticatedLayoutProjectsProjectNameIdBillingRoute
  '/projects/$projectNameId/settings': typeof AuthenticatedLayoutProjectsProjectNameIdSettingsRouteWithChildren
  '/projects/$projectNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdTokensRoute
  '/teams/$groupId/billing': typeof AuthenticatedLayoutTeamsGroupIdBillingRoute
  '/teams/$groupId/members': typeof AuthenticatedLayoutTeamsGroupIdMembersRoute
  '/teams/$groupId/settings': typeof AuthenticatedLayoutTeamsGroupIdSettingsRouteWithChildren
  '/projects/$projectNameId/': typeof AuthenticatedLayoutProjectsProjectNameIdIndexRoute
  '/teams/$groupId/': typeof AuthenticatedLayoutTeamsGroupIdIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteWithChildren
  '/projects/$projectNameId/namespaces/$': typeof AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute
  '/projects/$projectNameId/settings/': typeof AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute
  '/teams/$groupId/settings/': typeof AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/actors': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteWithChildren
  '/projects/$projectNameId/environments/$environmentNameId/builds': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute
  '/projects/$projectNameId/environments/$environmentNameId/cdn': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteWithChildren
  '/projects/$projectNameId/environments/$environmentNameId/matchmaker': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute
  '/projects/$projectNameId/environments/$environmentNameId/servers': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren
  '/projects/$projectNameId/environments/$environmentNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute
  '/projects/$projectNameId/environments/$environmentNameId/versions': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute
  '/projects/$projectNameId/environments/$environmentNameId/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend/variables': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies/settings': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute
  '/projects/$projectNameId/environments/$environmentNameId/servers/$': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginRoute
  '/billing': typeof AuthenticatedBillingRoute
  '/devices/link': typeof AuthenticatedDevicesLinkRoute
  '/invite/$inviteCode': typeof AuthenticatedInviteInviteCodeRoute
  '/': typeof AuthenticatedLayoutIndexRoute
  '/games/$': typeof AuthenticatedLayoutGamesSplatRoute
  '/my-profile/features': typeof AuthenticatedLayoutMyProfileFeaturesRoute
  '/devices/link/$token': typeof AuthenticatedDevicesLinkTokenRoute
  '/my-profile': typeof AuthenticatedLayoutMyProfileIndexRoute
  '/projects/$projectNameId/billing': typeof AuthenticatedLayoutProjectsProjectNameIdBillingRoute
  '/projects/$projectNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdTokensRoute
  '/teams/$groupId/billing': typeof AuthenticatedLayoutTeamsGroupIdBillingRoute
  '/teams/$groupId/members': typeof AuthenticatedLayoutTeamsGroupIdMembersRoute
  '/projects/$projectNameId': typeof AuthenticatedLayoutProjectsProjectNameIdIndexRoute
  '/teams/$groupId': typeof AuthenticatedLayoutTeamsGroupIdIndexRoute
  '/projects/$projectNameId/namespaces/$': typeof AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute
  '/projects/$projectNameId/settings': typeof AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute
  '/teams/$groupId/settings': typeof AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/actors': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute
  '/projects/$projectNameId/environments/$environmentNameId/builds': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute
  '/projects/$projectNameId/environments/$environmentNameId/cdn': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute
  '/projects/$projectNameId/environments/$environmentNameId/matchmaker': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute
  '/projects/$projectNameId/environments/$environmentNameId/servers': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren
  '/projects/$projectNameId/environments/$environmentNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute
  '/projects/$projectNameId/environments/$environmentNameId/versions': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute
  '/projects/$projectNameId/environments/$environmentNameId': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend/variables': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies/settings': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute
  '/projects/$projectNameId/environments/$environmentNameId/servers/$': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute
  '/projects/$projectNameId/environments/$environmentNameId/backend': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute
  '/projects/$projectNameId/environments/$environmentNameId/lobbies': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/login': typeof LoginRoute
  '/_authenticated/_layout': typeof AuthenticatedLayoutRouteWithChildren
  '/_authenticated/billing': typeof AuthenticatedBillingRoute
  '/_authenticated/_layout/my-profile': typeof AuthenticatedLayoutMyProfileRouteWithChildren
  '/_authenticated/devices/link': typeof AuthenticatedDevicesLinkRoute
  '/_authenticated/invite/$inviteCode': typeof AuthenticatedInviteInviteCodeRoute
  '/_authenticated/_layout/': typeof AuthenticatedLayoutIndexRoute
  '/_authenticated/_layout/games/$': typeof AuthenticatedLayoutGamesSplatRoute
  '/_authenticated/_layout/my-profile/features': typeof AuthenticatedLayoutMyProfileFeaturesRoute
  '/_authenticated/_layout/projects/$projectNameId': typeof AuthenticatedLayoutProjectsProjectNameIdRouteWithChildren
  '/_authenticated/_layout/teams/$groupId': typeof AuthenticatedLayoutTeamsGroupIdRouteWithChildren
  '/_authenticated/devices/link_/$token': typeof AuthenticatedDevicesLinkTokenRoute
  '/_authenticated/_layout/my-profile/': typeof AuthenticatedLayoutMyProfileIndexRoute
  '/_authenticated/_layout/projects/$projectNameId/billing': typeof AuthenticatedLayoutProjectsProjectNameIdBillingRoute
  '/_authenticated/_layout/projects/$projectNameId/settings': typeof AuthenticatedLayoutProjectsProjectNameIdSettingsRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdTokensRoute
  '/_authenticated/_layout/teams/$groupId/billing': typeof AuthenticatedLayoutTeamsGroupIdBillingRoute
  '/_authenticated/_layout/teams/$groupId/members': typeof AuthenticatedLayoutTeamsGroupIdMembersRoute
  '/_authenticated/_layout/teams/$groupId/settings': typeof AuthenticatedLayoutTeamsGroupIdSettingsRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/': typeof AuthenticatedLayoutProjectsProjectNameIdIndexRoute
  '/_authenticated/_layout/teams/$groupId/': typeof AuthenticatedLayoutTeamsGroupIdIndexRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/namespaces/$': typeof AuthenticatedLayoutProjectsProjectNameIdNamespacesSplatRoute
  '/_authenticated/_layout/projects/$projectNameId/settings/': typeof AuthenticatedLayoutProjectsProjectNameIdSettingsIndexRoute
  '/_authenticated/_layout/teams/$groupId/settings/': typeof AuthenticatedLayoutTeamsGroupIdSettingsIndexRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdActorsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBuildsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdCdnRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdMatchmakerRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersRouteWithChildren
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdTokensRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdVersionsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdIndexRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendLogsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendVariablesRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesLogsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesSettingsRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdServersSplatRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdBackendIndexRoute
  '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/': typeof AuthenticatedLayoutProjectsProjectNameIdEnvironmentsEnvironmentNameIdLobbiesIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/billing'
    | '/my-profile'
    | '/devices/link'
    | '/invite/$inviteCode'
    | '/'
    | '/games/$'
    | '/my-profile/features'
    | '/projects/$projectNameId'
    | '/teams/$groupId'
    | '/devices/link/$token'
    | '/my-profile/'
    | '/projects/$projectNameId/billing'
    | '/projects/$projectNameId/settings'
    | '/projects/$projectNameId/tokens'
    | '/teams/$groupId/billing'
    | '/teams/$groupId/members'
    | '/teams/$groupId/settings'
    | '/projects/$projectNameId/'
    | '/teams/$groupId/'
    | '/projects/$projectNameId/environments/$environmentNameId'
    | '/projects/$projectNameId/namespaces/$'
    | '/projects/$projectNameId/settings/'
    | '/teams/$groupId/settings/'
    | '/projects/$projectNameId/environments/$environmentNameId/actors'
    | '/projects/$projectNameId/environments/$environmentNameId/backend'
    | '/projects/$projectNameId/environments/$environmentNameId/builds'
    | '/projects/$projectNameId/environments/$environmentNameId/cdn'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies'
    | '/projects/$projectNameId/environments/$environmentNameId/matchmaker'
    | '/projects/$projectNameId/environments/$environmentNameId/servers'
    | '/projects/$projectNameId/environments/$environmentNameId/tokens'
    | '/projects/$projectNameId/environments/$environmentNameId/versions'
    | '/projects/$projectNameId/environments/$environmentNameId/'
    | '/projects/$projectNameId/environments/$environmentNameId/backend/logs'
    | '/projects/$projectNameId/environments/$environmentNameId/backend/variables'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
    | '/projects/$projectNameId/environments/$environmentNameId/servers/$'
    | '/projects/$projectNameId/environments/$environmentNameId/backend/'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/login'
    | '/billing'
    | '/devices/link'
    | '/invite/$inviteCode'
    | '/'
    | '/games/$'
    | '/my-profile/features'
    | '/devices/link/$token'
    | '/my-profile'
    | '/projects/$projectNameId/billing'
    | '/projects/$projectNameId/tokens'
    | '/teams/$groupId/billing'
    | '/teams/$groupId/members'
    | '/projects/$projectNameId'
    | '/teams/$groupId'
    | '/projects/$projectNameId/namespaces/$'
    | '/projects/$projectNameId/settings'
    | '/teams/$groupId/settings'
    | '/projects/$projectNameId/environments/$environmentNameId/actors'
    | '/projects/$projectNameId/environments/$environmentNameId/builds'
    | '/projects/$projectNameId/environments/$environmentNameId/cdn'
    | '/projects/$projectNameId/environments/$environmentNameId/matchmaker'
    | '/projects/$projectNameId/environments/$environmentNameId/servers'
    | '/projects/$projectNameId/environments/$environmentNameId/tokens'
    | '/projects/$projectNameId/environments/$environmentNameId/versions'
    | '/projects/$projectNameId/environments/$environmentNameId'
    | '/projects/$projectNameId/environments/$environmentNameId/backend/logs'
    | '/projects/$projectNameId/environments/$environmentNameId/backend/variables'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
    | '/projects/$projectNameId/environments/$environmentNameId/servers/$'
    | '/projects/$projectNameId/environments/$environmentNameId/backend'
    | '/projects/$projectNameId/environments/$environmentNameId/lobbies'
  id:
    | '__root__'
    | '/_authenticated'
    | '/login'
    | '/_authenticated/_layout'
    | '/_authenticated/billing'
    | '/_authenticated/_layout/my-profile'
    | '/_authenticated/devices/link'
    | '/_authenticated/invite/$inviteCode'
    | '/_authenticated/_layout/'
    | '/_authenticated/_layout/games/$'
    | '/_authenticated/_layout/my-profile/features'
    | '/_authenticated/_layout/projects/$projectNameId'
    | '/_authenticated/_layout/teams/$groupId'
    | '/_authenticated/devices/link_/$token'
    | '/_authenticated/_layout/my-profile/'
    | '/_authenticated/_layout/projects/$projectNameId/billing'
    | '/_authenticated/_layout/projects/$projectNameId/settings'
    | '/_authenticated/_layout/projects/$projectNameId/tokens'
    | '/_authenticated/_layout/teams/$groupId/billing'
    | '/_authenticated/_layout/teams/$groupId/members'
    | '/_authenticated/_layout/teams/$groupId/settings'
    | '/_authenticated/_layout/projects/$projectNameId/'
    | '/_authenticated/_layout/teams/$groupId/'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId'
    | '/_authenticated/_layout/projects/$projectNameId/namespaces/$'
    | '/_authenticated/_layout/projects/$projectNameId/settings/'
    | '/_authenticated/_layout/teams/$groupId/settings/'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/'
    | '/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/login"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_layout",
        "/_authenticated/billing",
        "/_authenticated/devices/link",
        "/_authenticated/invite/$inviteCode",
        "/_authenticated/devices/link_/$token"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_authenticated/_layout": {
      "filePath": "_authenticated/_layout.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_layout/my-profile",
        "/_authenticated/_layout/",
        "/_authenticated/_layout/games/$",
        "/_authenticated/_layout/projects/$projectNameId",
        "/_authenticated/_layout/teams/$groupId"
      ]
    },
    "/_authenticated/billing": {
      "filePath": "_authenticated/billing.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_layout/my-profile": {
      "filePath": "_authenticated/_layout/my-profile.tsx",
      "parent": "/_authenticated/_layout",
      "children": [
        "/_authenticated/_layout/my-profile/features",
        "/_authenticated/_layout/my-profile/"
      ]
    },
    "/_authenticated/devices/link": {
      "filePath": "_authenticated/devices.link.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/invite/$inviteCode": {
      "filePath": "_authenticated/invite.$inviteCode.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_layout/": {
      "filePath": "_authenticated/_layout/index.tsx",
      "parent": "/_authenticated/_layout"
    },
    "/_authenticated/_layout/games/$": {
      "filePath": "_authenticated/_layout/games/$.tsx",
      "parent": "/_authenticated/_layout"
    },
    "/_authenticated/_layout/my-profile/features": {
      "filePath": "_authenticated/_layout/my-profile/features.tsx",
      "parent": "/_authenticated/_layout/my-profile"
    },
    "/_authenticated/_layout/projects/$projectNameId": {
      "filePath": "_authenticated/_layout/projects/$projectNameId.tsx",
      "parent": "/_authenticated/_layout",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/billing",
        "/_authenticated/_layout/projects/$projectNameId/settings",
        "/_authenticated/_layout/projects/$projectNameId/tokens",
        "/_authenticated/_layout/projects/$projectNameId/",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId",
        "/_authenticated/_layout/projects/$projectNameId/namespaces/$"
      ]
    },
    "/_authenticated/_layout/teams/$groupId": {
      "filePath": "_authenticated/_layout/teams/$groupId.tsx",
      "parent": "/_authenticated/_layout",
      "children": [
        "/_authenticated/_layout/teams/$groupId/billing",
        "/_authenticated/_layout/teams/$groupId/members",
        "/_authenticated/_layout/teams/$groupId/settings",
        "/_authenticated/_layout/teams/$groupId/"
      ]
    },
    "/_authenticated/devices/link_/$token": {
      "filePath": "_authenticated/devices.link_.$token.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_layout/my-profile/": {
      "filePath": "_authenticated/_layout/my-profile/index.tsx",
      "parent": "/_authenticated/_layout/my-profile"
    },
    "/_authenticated/_layout/projects/$projectNameId/billing": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/billing.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/settings": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/settings.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/settings/"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/tokens": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/tokens.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId"
    },
    "/_authenticated/_layout/teams/$groupId/billing": {
      "filePath": "_authenticated/_layout/teams/$groupId/billing.tsx",
      "parent": "/_authenticated/_layout/teams/$groupId"
    },
    "/_authenticated/_layout/teams/$groupId/members": {
      "filePath": "_authenticated/_layout/teams/$groupId/members.tsx",
      "parent": "/_authenticated/_layout/teams/$groupId"
    },
    "/_authenticated/_layout/teams/$groupId/settings": {
      "filePath": "_authenticated/_layout/teams/$groupId/settings.tsx",
      "parent": "/_authenticated/_layout/teams/$groupId",
      "children": [
        "/_authenticated/_layout/teams/$groupId/settings/"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/index.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId"
    },
    "/_authenticated/_layout/teams/$groupId/": {
      "filePath": "_authenticated/_layout/teams/$groupId/index.tsx",
      "parent": "/_authenticated/_layout/teams/$groupId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/namespaces/$": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/namespaces/$.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/settings/": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/settings/index.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/settings"
    },
    "/_authenticated/_layout/teams/$groupId/settings/": {
      "filePath": "_authenticated/_layout/teams/$groupId/settings/index.tsx",
      "parent": "/_authenticated/_layout/teams/$groupId/settings"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/actors.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/builds.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/cdn.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings",
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/matchmaker.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId",
      "children": [
        "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$"
      ]
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/tokens.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/versions.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/index.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/logs.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/variables.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/logs.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/settings.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers/$.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/servers"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend/index.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/backend"
    },
    "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/": {
      "filePath": "_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies/index.tsx",
      "parent": "/_authenticated/_layout/projects/$projectNameId/environments/$environmentNameId/lobbies"
    }
  }
}
ROUTE_MANIFEST_END */
