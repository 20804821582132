interface LobbyLifecycleProps {
	createTs: Date;
	readyTs?: Date;
	stopTs?: Date;
}

export function LobbyLifecycle({
	createTs,
	readyTs,
	stopTs,
}: LobbyLifecycleProps) {
	return null;
}
